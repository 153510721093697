import React from "react";
import theme from "theme";
import { Theme, Link, Text, Button, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about-us"} />
		<Helmet>
			<title>
				CyberSecurity
			</title>
			<meta name={"description"} content={"Cybersecurity is the protection of internet-connected systems such as hardware, software and data from cyberthreats."} />
			<meta property={"og:title"} content={"la"} />
			<meta property={"og:description"} content={"Chat with a doctor right from your phone, all day and night. No waiting room or appointment needed."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6123949e957019001e284458/images/OGimage.png?v=2021-09-21T16:25:40.647Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6315f5e35e5c6e00215659a7/images/mod.png?v=2022-09-12T09:21:49.865Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6315f5e35e5c6e00215659a7/images/mod.png?v=2022-09-12T09:21:49.865Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6315f5e35e5c6e00215659a7/images/mod.png?v=2022-09-12T09:21:49.865Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6315f5e35e5c6e00215659a7/images/mod.png?v=2022-09-12T09:21:49.865Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6315f5e35e5c6e00215659a7/images/mod.png?v=2022-09-12T09:21:49.865Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6315f5e35e5c6e00215659a7/images/mod.png?v=2022-09-12T09:21:49.865Z"} />
			<meta name={"msapplication-TileColor"} content={"#000848"} />
		</Helmet>
		<Section padding="80px 0" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" />
			<Text as="h2" font="--headline1" md-font="--headline2" margin="20px 0 0 0">
				About Us
			</Text>
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
				<br />
				<br />
				The Cybernet has been providing IT support and services to businesses Worldwide since 2020 We believe every organization should have quick access to affordable IT support. Our knowledgeable technicians have the expertise to handle all types of support, from the simple maintenance of an individual’s workstation to problems affecting your entire IT infrastructure.
				<br />
				<br />
				We strive to eliminate your IT issues before they cause the expensive downtime that’s been known to hinder operational efficiency. At CYBERNET, you will get support from our dedicated team of enthusiastic IT professionals.
				<br />
				It takes multiple trips or calls just to solve a single problem
				<br />
				And the resulting bills are always a surprise
				<br />
				When I get in front of business owners like you, I can show you easily how you’re at risk … way beyond the everyday frustrations and inefficiency you see.
				<br />
				What you don’t know about your IT has your data – and maybe your entire business – at risk. Give me 30 minutes and I’ll show you the remedy.
				<br />
				I have built an incredible team with a common goal to keep your data secure, your staff productive and your business profitable, all while providing an excellent customer experience.
				<br />
				If you have 5-200 employees and want to experience a better way, give me a call. I’d love the opportunity to explore whether or not we’re a good fit for each other.{"\n\n"}
			</Text>
			<Button
				font="--lead"
				margin="20px"
				href="https://cybernet.al/contact"
				target="_self"
				border-radius="24px"
			>
				Contact
			</Button>
		</Section>
		<Link
			font={"--capture"}
			font-size={"10px"}
			position={"fixed"}
			bottom={"12px"}
			right={"12px"}
			z-index={"4"}
			border-radius={"4px"}
			padding={"5px 12px 4px"}
			background-color={"--dark"}
			opacity={"0.6"}
			hover-opacity={"1"}
			color={"--light"}
			cursor={"pointer"}
			transition={"--opacityOut"}
			quarkly-title={"Badge"}
			text-decoration-line={"initial"}
			href={"https://quarkly.io/"}
			target={"_blank"}
		>
			Made on Quarkly
		</Link>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6123949e957019001e284456"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});