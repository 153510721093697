export default {
	"pages": {
		"root": {
			"id": "root",
			"pageUrl": "root",
			"name": "root",
			"children": [
				"6315f5e35e5c6e00215659bf",
				"6315f5e35e5c6e00215659bb",
				"63308accf45b7e00247a210e",
				"633170375e5c6e002156c1c6",
				"6334938b21e43d0020994718"
			]
		},
		"6315f5e35e5c6e00215659bb": {
			"id": "6315f5e35e5c6e00215659bb",
			"name": "404",
			"pageUrl": "404"
		},
		"6315f5e35e5c6e00215659bf": {
			"id": "6315f5e35e5c6e00215659bf",
			"name": "index",
			"pageUrl": "index"
		},
		"63308accf45b7e00247a210e": {
			"id": "63308accf45b7e00247a210e",
			"pageUrl": "services",
			"name": "Write us"
		},
		"633170375e5c6e002156c1c6": {
			"id": "633170375e5c6e002156c1c6",
			"pageUrl": "contact",
			"name": "Contact"
		},
		"6334938b21e43d0020994718": {
			"id": "6334938b21e43d0020994718",
			"pageUrl": "about-us",
			"name": "About us"
		}
	},
	"mode": "production",
	"projectType": "create-react-app",
	"site": {
		"styles": {},
		"seo": {
			"favicon_apple_152px": "https://uploads.quarkly.io/6315f5e35e5c6e00215659a7/images/mod.png?v=2022-09-12T09:21:49.865Z",
			"favicon_32px": "https://uploads.quarkly.io/6315f5e35e5c6e00215659a7/images/mod.png?v=2022-09-12T09:21:49.865Z",
			"favicon_win10_270px": "https://uploads.quarkly.io/6315f5e35e5c6e00215659a7/images/mod.png?v=2022-09-12T09:21:49.865Z",
			"background_win10": "#000848",
			"og:title": "la",
			"og:description": "Chat with a doctor right from your phone, all day and night. No waiting room or appointment needed.",
			"og:image": "https://uploads.quarkly.io/6123949e957019001e284458/images/OGimage.png?v=2021-09-21T16:25:40.647Z",
			"title": "CyberSecurity",
			"description": "Cybersecurity is the protection of internet-connected systems such as hardware, software and data from cyberthreats.",
			"robotsFileType": "autogenerated"
		}
	}
}